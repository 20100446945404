import { useEffect, FC, useRef } from "react";
import { useRouter } from "next/router";
import { getInnerWindowSize } from "@/common/utils/sharedFunctions";
import dynamic from "next/dynamic";
const CustomModal = dynamic(() => import("@/components/portals/CustomModal/CustomModal"), { ssr: false });
import SignupForm from "@/components/core/SignupForm/SignupFormComponent";
import { useModal } from "@/components/context/ModalContext";
import { useTranslation } from "next-i18next";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
// STYLE
import s from "./Banner.module.scss";
import Link from "next/link";
import LinkButton from "@/components/elements/LinkButton/LinkButton";

interface BannerProps {
  color: any;
  columns: Array<any>;
  links?: Array<any>;
  signupModal?: boolean;
  t: any;
  buttonVariant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link"
    | "outline-primary"
    | "outline-secondary"
    | "outline-success"
    | "outline-danger"
    | "outline-warning"
    | "outline-info"
    | "outline-light"
    | "outline-dark";
}

const Banner: FC<BannerProps> = ({ color, columns, links, signupModal = false, buttonVariant = "outline-dark", t }) => {
  const router = useRouter();
  // const { t } = useTranslation("common");

  let windowSize = useRef({ height: 0, width: 0 });
  useEffect(() => {
    windowSize.current = getInnerWindowSize();
  }, []);

  let visibleColumns = columns.length;
  if (windowSize.current.width < 991.98) {
    visibleColumns = 1;
  }

  // Custom modal context state
  const { showModal } = useModal();

  // Signup Modal
  const showSignupModal = () =>
    showModal(CustomModal, {
      backdrop: "static",
      children: <SignupForm />,
      closeButton: true,
    });

  return (
    <div className={`w-100 py-5 ${color} opacity-75`}>
      <div>
        {columns.slice(0, visibleColumns).map((column, index) => {
          return (
            <div key={index}>
              {column.title && (
                <div className="text-center px-2">
                  <h2 className="pb-2 fs-2">{t(column.title)}</h2>
                </div>
              )}
              {column.subtitle && (
                <div className="px-4 text-center text-md-center">
                  <h3 key={index} className={`fs-5 fw-semibold ${s.subtitle}`}>
                    {t("landing-page.banner-subtitle-2")}
                  </h3>
                  {column.subtitle.map((text, index) => (
                    <h3 key={index} className={`fs-6 ${s.subtitle}`}>
                      {t(text)}
                    </h3>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {links && (
        <div className="d-flex justify-content-center pt-2">
          {links.map((link, index) => (
            <div key={index}>
              <LinkButton href={link.href} buttonStyle="fw-bold text-dark border border-1 border-dark bg-light py-2 px-3 w-100">
                <span className="text-uppercase">{t(link.text)}</span>
              </LinkButton>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Banner;
