import { fetcher } from "@/pages/api/util/fetcher";

export /**
 * get final price
 * @param price
 * @param discount
 * @returns
 */
  const getFinalPrice = (price, discount) => {
    let finalPrice;
    let numberPrice = Number(commaToDot(price) || 0);
    let numberDiscount = Number(commaToDot(discount) || 0);

    if (numberDiscount > 0) {
      finalPrice = numberPrice - numberPrice * (numberDiscount / 100);
    } else {
      finalPrice = numberPrice;
    }
    return finalPrice.toFixed(2);
  };

export /**
 * season id based on creation date of a product
 * @param product
 */
  const getSeasonsId = (date: Date, previous?: boolean) => {
    // Spring/Summer goes from January to July, and Fall/Winter goes from August to December
    // Create an array with 2 seasons, current, next.
    // If previous is true Create an array with 3 seasons, previous, current, next.
    let seasonsId = [];
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month >= 0 && month <= 6) {
      seasonsId[1] = "SS" + year;
      seasonsId[2] = "FW" + year;
      previous ? seasonsId[0] = "FW" + (year - 1) : null;
    } else if (month >= 7 && month <= 11) {
      seasonsId[1] = "FW" + year;
      seasonsId[2] = "SS" + (year + 1);
      previous ? seasonsId[0] = "SS" + (year) : null;
      // seasonsId[2] = "FW" + (year + 1);
    }
    return seasonsId;
  };

export /**
 * season id based on creation date of a product
 * @param product
 */
  const getSeasonsLabel = (season: string) => {
    if (season)
      switch (season.slice(0, 2)) {
        case "SS":
          return "spring-summer" + " " + season.slice(2, season.length);
        case "FW":
          return "autumn-winter" + " " + season.slice(2, season.length);
        default:
          return season;
      }
  };

export const getSorted = (values, options) => {
  return values.sort((a, b) => (getIndex(a, options) > getIndex(b, options) ? 1 : -1));
};

/**
 * Retrieves index based on size name
 * @param value
 * @returns  integer as index
 */
const getIndex = (value: any, options) => {
  return Number(options.find((el) => el.key == value)?.id);
};

// transform string to a number only if it's a string and if contains a dot replace it with a comma
export const commaToDot = (value: string) => {
  if (typeof value === "string" && value.includes(",")) {
    return value.replace(",", ".");
  }
  return value;
};


export const getCSVKollaBarcodes = async (codes) => {
  return await fetcher("/api/barcodes/export" + "?" + new URLSearchParams({ codes: codes.toString(',') }).toString());
};

export const setKollaDiscount = async (codes, discount) => {

  return await fetcher("/api/product/kolla-discount", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({codes, discount}),
  });
};

