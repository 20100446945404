import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import fallbackTranslation from "@/lib/dbTranslations";
import { useApp } from "@/components/context/AppContext/AppContext";
import LinkButton from "@/components/elements/LinkButton/LinkButton";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// PICTURES
import verticalPhoto1 from "@/public/static/landingPage/verticalPhoto1.webp";
import verticalPhoto2 from "@/public/static/landingPage/verticalPhoto2.webp";
import verticalPhoto3 from "@/public/static/landingPage/verticalPhoto3.webp";
import verticalPhoto4 from "@/public/static/landingPage/verticalPhoto4.webp";
// STYLE
import s from "./VerticalPhotoBanner.module.scss";

export default function VerticalPhotoBanner({ button, subtitle, title }) {
  const app = useApp();
  const { t } = useTranslation(["common", "db-translations"]);
  const router = useRouter();

  const [verticalBannerItems, setVerticalBannerItems] = useState([
    {
      id: 1,
      buttonText: "landing-page.browse",
      href: "/" + router.locale + "/catalogue/tshirts?Page=1",
      key: "TSHIRTS",
      subtitle: "discover-best-items",
      src: verticalPhoto1,
    },
    {
      id: 2,
      buttonText: "landing-page.browse",
      href: "/" + router.locale + "/catalogue/shirts?Page=1",
      key: "SHIRTS",
      subtitle: "discover-best-items",
      src: verticalPhoto2,
    },
    {
      id: 3,
      buttonText: "landing-page.browse",
      href: "/" + router.locale + "/catalogue/trousers?Page=1",
      key: "TROUSERS",
      subtitle: "discover-best-items",
      src: verticalPhoto3,
    },
    {
      id: 4,
      buttonText: "landing-page.browse",
      href: "/" + router.locale + "/catalogue/clothes?Page=1",
      key: "CLOTHES",
      subtitle: "discover-best-items",
      src: verticalPhoto4,
    },
  ]);
  return (
    <Row className={`mx-0 ${s.wrapper}`}>
      <h2 className="fs-5 fw-semibold pb-2 ps-2 mb-0">{t(`landing-page.best-categories`)}</h2>
      {verticalBannerItems.map((item, index) => (
        <Link href={item.href} key={index} passHref>
          <Col xs="6" xl="3" className="p-1">
            <Card style={{ cursor: "pointer", border: "none" }}>
              <div style={{ width: "100%" }}>
                <Image
                  layout="responsive"
                  width={100}
                  height={150}
                  src={item.src}
                  sizes="(max-width: 1200px) 40vw, 20vw"
                  alt="horizontal_banner"
                  className={s.image}
                  quality={60}
                />
              </div>
              <Card.ImgOverlay className={`${s.card_overlay} p-4`}>
                <div className={`${s.text_wrapper} text-center`}>
                  {subtitle && (
                    <div className={`${s.banner_subtitle}`}>
                      <h3 className="text-center fs-6">{t(`landing-page.${item.subtitle}`)}</h3>
                    </div>
                  )}
                  {title && (
                    <div className={`${s.banner_title} pb-3 align-text-center`}>
                      <h2 className={`fs-2 ${s.title}`}>
                        {fallbackTranslation(
                          "clothing-product-group",
                          "db-translations",
                          app.appConfig?.clothingProductsGroup && app.appConfig?.clothingProductsGroup.find((el) => el.key == item.key),
                          t,
                          router.locale
                        ).toUpperCase()}
                      </h2>
                    </div>
                  )}
                  {button && (
                    <div className={`${s.button}`}>
                      <LinkButton href={item.href} buttonStyle="fw-bold text-dark border border-1 border-dark bg-light py-2 px-3 w-100">
                        <span className="text-uppercase">{t(item.buttonText)}</span>
                      </LinkButton>
                    </div>
                  )}
                </div>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Link>
      ))}
    </Row>
  );
}
