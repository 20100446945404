import Link from "next/link";
import React from "react";
import { useTranslation } from "next-i18next";
import s from "./LinkButton.module.scss";

interface LinkButtonProps {
  buttonStyle: string;
  children: React.ReactNode;
  href: string;
}

export default function LinkButton({ children, href, buttonStyle }: LinkButtonProps) {
  const { t } = useTranslation("common");

  return (
    <Link href={href} passHref>
      <div className={`${buttonStyle} ${s.linkButton}`}>
        {children}
      </div>
    </Link>
  );
}
