import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import LinkButton from "@/components/elements/LinkButton/LinkButton";
// BOOTSTRAP
import Card from "react-bootstrap/Card";
// STYLE / IMAGES
import s from "./Hero.module.scss";
import image from "@/public/static/images/hero_autumn.webp";
import { useState, useEffect } from "react";

interface HeroProps {
  buttonHref: string;
  buttonText: string;
  title: string;
  text: string;
  text_footer: string;
}
export default function Hero({ buttonHref, buttonText, title, text, text_footer }: HeroProps) {
  const { t } = useTranslation("common");

  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  return (
    <Link href={buttonHref} passHref>
      <div>
        <Card style={{ cursor: "pointer", border: "none", position: "relative", height: "30rem" }}>
          <Image
            layout="fill"
            objectFit="cover"
            alt={"hero image"}
            width={150}
            height={150}
            src={image}
            className={s.image}
            priority={true}
            sizes="100vw"
          />
          <Card.ImgOverlay className="p-0">
            <div className={`${s.text_wrapper} align-items-center 
            text-center`}>
              <div className="h-20 d-grid align-items-center">
                <div className="py-2">
                <h1 style={{ fontSize: "3rem", color: "#ffc8c8", fontWeight: "bold" }}>{title}</h1>
                </div>
                <div className="py-2">
                  <h4 className="text-light">{text}</h4>
                </div>
                {/* <div className="py-2">
                  <h4 className="text-light">{text_footer}</h4>
                </div> */}

                <div className="py-2 d-flex justify-content-center">
                  <LinkButton href={buttonHref} buttonStyle="fw-bold text-dark border border-1 border-dark bg-light py-2 px-3 w-50">
                    <span className="text-uppercase">{t(buttonText)}</span>
                  </LinkButton>
                </div>
              </div>
            </div>
          </Card.ImgOverlay>
        </Card>
      </div>
    </Link>
  );
}
