import Spinner from "react-bootstrap/Spinner";

export default function BootstrapSpinner() {
  return (
    <div
      style={{
        marginBottom: "10rem",
        height: "30rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner animation="border" style={{ width: "10rem", height: "10rem" }} role="status" />
    </div>
  );
}
